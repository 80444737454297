import {Avatar, Button, Col, Layout, Row} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {ReactNode} from "react";
import {useAuth, useUser} from "reactfire";

const {Header, Content, Footer} = Layout;

export default function PageLayout({children, loggedIn}: { children: ReactNode, loggedIn: boolean }) {

    const auth = useAuth();
    const {data: user, status} = useUser();

    return (
        <Layout className="layout">
            <Header>
                <div className="logo">
                    Electo admnistravimas
                </div>
                {(loggedIn && status === 'success') &&
                <Row className="account" gutter={16}>
                    <Col>
                        <Avatar size="large" icon={<UserOutlined/>}/>
                    </Col>
                    <Col>
                        <p>{user?.displayName}</p>
                    </Col>
                    <Col>
                        <Button
                            onClick={async () => {
                            await auth.signOut();
                            window.location.reload();
                        }}
                        >
                            Atsijungti
                        </Button>
                    </Col>
                </Row>
                }
            </Header>
            <Content style={{padding: '50px'}}>
                <div className="content">
                    {children}
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©2021 Artificia</Footer>
        </Layout>
    )
}
