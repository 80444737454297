import {Question, QuestionDoc} from "./useQuestions";
import {CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleTwoTone} from "@ant-design/icons";

export const catDict = {
    culture: "Kultūra",
    foreignPolicy: "Užsienio politika",
    economics: "Ekonomika",
    internalPolicy: "Vidaus politika"
} as const;

const opinions = [
    {
        index: 0,
        label: "Sutinka",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a"/>
    },
    {
        index: 1,
        label: "Neturi nuomonės",
        icon: <QuestionCircleTwoTone twoToneColor="#fa8c16"/>
    },
    {
        index: 2,
        label: "Nesutinka",
        icon: <CloseCircleTwoTone twoToneColor="#f5222d"/>
    }] as const;

export const categoryInLT = (category: keyof QuestionDoc) => catDict[category]

export const getOpinion = (idx: number) => opinions[idx]

export type QuestionRow = Question & {
    category: keyof QuestionDoc
}

export const tranformQuestions = (questions: QuestionDoc) => {
    let arr: QuestionRow[] = [];
    for (const [category, values] of Object.entries(questions)) {
        if (Array.isArray(values)) {
            arr = arr.concat(values.map(val => ({
                ...val,
                category: category
            })) as any)
        }
    }
    return arr;
};

export const questionsEqual = (q1: Question, q2: Question) => {
    for (const key of Object.keys(q1)) {
        if (q1[key as keyof Question] !== q2[key as keyof Question]) {
            return false;
        }
    }
    return true;
}


export const TableI18n = {
    filterTitle: 'Filtrų meniu',
    filterConfirm: 'Gerai',
    filterReset: 'Atstatyti',
    filterEmptyText: 'Nėra filtrų',
    selectAll: 'Pasirinkti visą puslapį',
    selectInvert: 'Apsukti viso puslapio pasirinkimus',
    selectionAll: 'Pasirinkti visus duomenis',
    sortTitle: 'Rikiuoti',
    expand: 'Išskleisti eilutę',
    collapse: 'Sutraukti eilutę',
    triggerDesc: 'Rikiuoti mažėjančiai',
    triggerAsc: 'Rikiuoti didėjančiai',
    cancelSort: 'Atšaukti rikiavimą',
}
