import {useFirestore, useFirestoreDocData} from "reactfire";
import {useCallback, useMemo} from "react";
import {QuestionRow} from "./utils";
import {message} from "antd";
import firebase from "firebase";

export type Question = {
    question: string
    jaunimas: 0 | 1 | 2
    miestieciai: 0 | 1 | 2
    regionai: 0 | 1 | 2
    senjorai: 0 | 1 | 2
}

export type QuestionDoc = {
    culture: Question[]
    economics: Question[]
    foreignPolicy: Question[]
    internalPolicy: Question[]
}

const devQuestions1: QuestionDoc = {
    culture:[
        {
            question:"culture question 1",
            jaunimas: 1,
            senjorai: 1,
            miestieciai: 1,
            regionai: 1,
        }
    ],
    economics:[
        {
            question:"economics question 1",
            jaunimas: 1,
            senjorai: 1,
            miestieciai: 1,
            regionai: 1,
        }
    ],
    foreignPolicy:[
        {
            question:"foreignPolicy question 1",
            jaunimas: 1,
            senjorai: 1,
            miestieciai: 1,
            regionai: 1,
        }
    ],
    internalPolicy:[
        {
            question:"internalPolicy question 1",
            jaunimas: 1,
            senjorai: 1,
            miestieciai: 1,
            regionai: 1,
        }
    ]
}

export default function useQuestions(questionSetNum: 1 | 2) {
    const firestore = useFirestore();
    //react does not allow conditional hook calls
    const questionsRef1 = useMemo(() => firestore.collection('globals').doc('questions'),[firestore]) 
    const questionsRef2 = useMemo(() => firestore.collection('globals').doc('questions2'),[firestore]) 
    const questionsRef = questionSetNum == 1 ? questionsRef1 : questionsRef2;
    
    const {status, data: questions} = useFirestoreDocData<QuestionDoc>(questionsRef);

    const remove = useCallback(async (question: QuestionRow) => {
        const {category, ...newQuestion} = question;
        const newQuestions = [...questions[category]];
        if (newQuestions.length <= 2) {
            message.error("Trynimas negalimas - kategorijoje liks per mažai teiginių!");
        } else {
            await questionsRef.update({[category]: firebase.firestore.FieldValue.arrayRemove(newQuestion)});
        }

    }, [questions, questionsRef])
    const add = useCallback(async (question: QuestionRow) => {
        const {category, ...newQuestion} = question;
        await questionsRef.update({[category]: firebase.firestore.FieldValue.arrayUnion(newQuestion)});
    }, [questionsRef])

    return {status, questions, add, remove}
}
