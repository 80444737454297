import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "antd/dist/antd.css";
import {FirebaseAppProvider} from 'reactfire';
import firebaseConfig from "./firebaseConfig";
import "./styles.css";
import Loading from "./Loading";


ReactDOM.render(
    <React.StrictMode>
        <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
            <React.Suspense fallback={<Loading/>}>
                <App/>
            </React.Suspense>
        </FirebaseAppProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

