import {Button, Form, message, Select} from "antd";
import {forwardRef, useState} from "react";
import {catDict, getOpinion, QuestionRow} from "./utils";
import TextArea from "antd/es/input/TextArea";

const OpinionSelect = forwardRef<any, any>((props, ref) => (
    <Select placeholder="Pasirinkite nuomonę" style={{width: 170}} {...props} ref={ref}>
        <Select.Option value={0}>
            {getOpinion(0).icon} {getOpinion(0).label}
        </Select.Option>
        <Select.Option value={1}>
            {getOpinion(1).icon} {getOpinion(1).label}
        </Select.Option>
        <Select.Option value={2}>
            {getOpinion(2).icon} {getOpinion(2).label}
        </Select.Option>
    </Select>
));

export default function NewQuestionForm({onSubmit}: { onSubmit: (question: QuestionRow) => Promise<void> }) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (formValue: any) => {
        setLoading(true);
        try {
            await onSubmit(formValue);
            message.success('Teiginys sėkmingai pridėtas!');
        } catch (e) {
            console.error(e);
            message.error('Klaida! Bandykite dar kartą.');

        } finally {
            setLoading(false);
        }
    }

    return (
        <Form
            name="addNewQuestion"
            requiredMark={false}
            layout="vertical"
            onFinish={handleSubmit}
            style={{maxWidth: 800, margin: '0 auto'}}
        >
            <Form.Item
                label="Teiginys"
                name="question"
                rules={[{required: true, message: 'Įveskite teiginį'}]}
            >
                <TextArea/>
            </Form.Item>

            <Form.Item
                label="Kategorija"
                name="category"
                rules={[{required: true, message: 'Pasirinkite kategoriją'}]}
            >
                <Select placeholder="Pasirinkite kategoriją">
                    {Object.entries(catDict).map(([val, label]) => (
                        <Select.Option value={val} key={val}>{label}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <div style={{display: "flex", justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <Form.Item
                    label="Jaunimas"
                    name="jaunimas"
                    rules={[{required: true, message: 'Pasirinkite nuomonę'}]}
                >
                    <OpinionSelect/>
                </Form.Item>
                <Form.Item
                    label="Miestiečiai"
                    name="miestieciai"
                    rules={[{required: true, message: 'Pasirinkite nuomonę'}]}
                >
                    <OpinionSelect/>
                </Form.Item>
                <Form.Item
                    label="Regionai"
                    name="regionai"
                    rules={[{required: true, message: 'Pasirinkite nuomonę'}]}
                >
                    <OpinionSelect/>
                </Form.Item>
                <Form.Item
                    label="Senjorai"
                    name="senjorai"
                    rules={[{required: true, message: 'Pasirinkite nuomonę'}]}
                >
                    <OpinionSelect/>
                </Form.Item>
            </div>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Pridėti
                </Button>
            </Form.Item>
        </Form>
    )
}
