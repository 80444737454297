const firebaseConfig = {
    apiKey: "AIzaSyARsCiUqGa85fVZMM7U9BYR3yZb8DwkVkQ",
    authDomain: "electo-artificia.firebaseapp.com",
    databaseURL: "https://electo-artificia.firebaseio.com",
    projectId: "electo-artificia",
    storageBucket: "electo-artificia.appspot.com",
    messagingSenderId: "984301797232",
    appId: "1:984301797232:web:013b39b502fc750369d9b5",
    measurementId: "G-6S1J900BW4"
};

export default firebaseConfig;
