import {Alert, Button, Form, Input} from "antd";
import {useAuth} from "reactfire";
import {useState} from "react";

export default function LoginForm() {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ message: string } | null>(null);
    const auth = useAuth();

    const handleLogin = async (values: { username: string, password: string }) => {
        try {
            setLoading(true);
            setError(null);
            const domain = "https://api.electo.lt" //`${process.env.REACT_APP_API_ENDPOINT}`
            const endpoint = `${domain}/api/admin/login`;
            const resp = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values)
            });
            if (resp.ok) {
                const {token} = await resp.json();
                const userCredential = await auth.signInWithCustomToken(token);
            } else if (resp.status === 422 || resp.status === 401) {
                const error = await resp.json();
                setError(error);
            } else {
                console.error(resp);
                setError({ message: "Bandykite dar kartą"})
            }
        } catch (e) {
            console.error(e);
            setError({ message: "Bandykite dar kartą"})
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {error && <Alert
                message="Klaida"
                description={error.message}
                type="error"
                style={{ marginBottom: "1em" }}
            />}
            <Form
                name="login"
                style={{ maxWidth: 500, margin: "auto" }}
                requiredMark={false}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{remember: true}}
                onFinish={handleLogin}
            >
                <Form.Item
                    label="Vartotojas"
                    name="username"
                    rules={[{required: true, message: 'Įveskite vartotojo vardą!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Slaptažodis"
                    name="password"
                    rules={[{required: true, message: 'Įveskite slaptažodį!'}]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ textAlign: "left" }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Prisijungti
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
