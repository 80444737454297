import React, {useEffect, useState} from "react";
import useQuestions, {QuestionDoc} from "./useQuestions";
import Title from "antd/lib/typography/Title";
import {Button, Divider, message, Modal, Table, Tooltip} from "antd";
import {catDict, categoryInLT, getOpinion, QuestionRow, TableI18n, tranformQuestions} from "./utils";
import {DeleteTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import NewQuestionForm from "./NewQuestionForm";
import QuestionStats from "./QuestionStats";

const {Column, ColumnGroup} = Table;

const QuestionModification = ({questionSetNum} : {questionSetNum : 1 | 2}) => {



    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<QuestionRow[]>([]);

    const {questions, remove, add} = useQuestions(questionSetNum);

    useEffect(() => {
        setData(tranformQuestions(questions));
    }, [questions])

    const handleRemove = async (question: QuestionRow) => {
        setLoading(true);
        try {
            await remove(question);
        } catch (e) {
            console.error(e);
            message.error('Klaida! Bandykite dar kartą.');
        } finally {
            setLoading(false);
        }
    }
    return (
        <div>
            <Title level={2}>Teiginiai</Title>
            <QuestionStats questions={data}/>
            <Table
                dataSource={data}
                locale={TableI18n}
                loading={loading}
            >
                <Column title="Teiginys" dataIndex="question" key="question"/>
                <Column
                    title="Kategorija"
                    dataIndex="category"
                    key="category"
                    filters={Object.keys(catDict).map(cat => ({
                        text: categoryInLT(cat as keyof QuestionDoc),
                        value: cat
                    }))}
                    onFilter={(val, record: QuestionRow) => record.category === val}
                    render={(cat) => categoryInLT(cat)}
                />
                <ColumnGroup title="Grupės">
                    <Column
                        title="Jaunimas"
                        dataIndex="jaunimas"
                        key="jaunimas"
                        align="center"
                        render={(opinionIdx: number) => {
                            const opinion = getOpinion(opinionIdx);
                            return <Tooltip title={opinion.label} placement="bottom">{opinion.icon}</Tooltip>;
                        }}
                    />
                    <Column
                        title="Miestiečiai"
                        dataIndex="miestieciai"
                        key="miestieciai"
                        align="center"
                        render={(opinionIdx: number) => {
                            const opinion = getOpinion(opinionIdx);
                            return <Tooltip title={opinion.label} placement="bottom">{opinion.icon}</Tooltip>;
                        }}/>
                    <Column
                        title="Regionai"
                        dataIndex="regionai"
                        key="regionai"
                        align="center"
                        render={(opinionIdx: number) => {
                            const opinion = getOpinion(opinionIdx);
                            return <Tooltip title={opinion.label} placement="bottom">{opinion.icon}</Tooltip>;
                        }}/>
                    <Column
                        title="Senjorai"
                        dataIndex="senjorai"
                        key="senjorai"
                        align="center"
                        render={(opinionIdx: number) => {
                            const opinion = getOpinion(opinionIdx);
                            return <Tooltip title={opinion.label} placement="bottom">{opinion.icon}</Tooltip>;
                        }}/>
                </ColumnGroup>
                <Column
                    title="Veiksmai"
                    key="actions"
                    align="center"
                    render={(val, record: QuestionRow) => (
                        <Button
                            type="text"
                            icon={<DeleteTwoTone twoToneColor="#ff4d4f"/>}
                            danger
                            onClick={() => Modal.confirm({
                                title: 'Teiginio trynimas',
                                icon: <ExclamationCircleOutlined/>,
                                content: 'Ar tikrai norite ištrinti?',
                                onOk: async () => handleRemove(record),
                                okText: 'Trinti',
                                cancelText: 'Atšaukti'
                            })}
                        />
                    )}
                />
            </Table>
            <Divider>Naujas teiginys</Divider>
            <NewQuestionForm onSubmit={add}/>
        </div>
    )
}

export default QuestionModification;
