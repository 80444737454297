import React, {useEffect, useState} from "react";
import {Button, Divider, message, Modal, Table, Tooltip, Form} from "antd";
import QuestionModification from "./QuestionModification";

const Main = () => {

    const [currentQuestionSetNum, setCurrentQuestionSetNum] = useState<1|2>(1);

    return (
        <div >
            <div style={{"justifyContent":"center","display":"flex", "flexDirection":"row","gap":"10px"}}>
                <Button type="primary" htmlType="button" onClick={()=>{setCurrentQuestionSetNum(1)}}>
                    Pielietiškumo mokykla
                </Button>
                <Button type="primary" htmlType="button" onClick={()=>{setCurrentQuestionSetNum(2)}}>
                    Europietiškumo mokykla
                </Button>
            </div>
            <h1>Rodomi klausimai: {currentQuestionSetNum == 1 ? "Pielietiškumo mokykla" : "Europietiškumo mokykla"}</h1>
            <Divider/>
            {
                <QuestionModification questionSetNum={currentQuestionSetNum}/>
            }
        </div>
    )
}

export default Main;
