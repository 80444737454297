import React from 'react';
import {preloadAuth, preloadFirestore, useFirebaseApp, useSigninCheck} from 'reactfire';
import LoginForm from "./LoginForm";
import QuestionModification from "./QuestionModification";
import PageLayout from "./PageLayout";
import firebase from "firebase/app";
import Main from './Main'

const preloadSDKs = (firebaseApp: firebase.app.App) => {
    return Promise.all([
        preloadFirestore({firebaseApp}),
        preloadAuth({firebaseApp})
    ]);
};

function App() {
    const firebaseApp = useFirebaseApp();
    preloadSDKs(firebaseApp);

    const {data: signInCheckResult} = useSigninCheck();

    const isProd = process ? process.env ? process.env.NODE_ENV ? process.env.NODE_ENV == "development" : false : false : false;

    return (
        <PageLayout loggedIn={signInCheckResult.signedIn}>
            {
                signInCheckResult.signedIn ? 
                    <Main/>
                : 
                    <LoginForm/>
            }
        </PageLayout>
    )
}

export default App;
