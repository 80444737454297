import {catDict, QuestionRow} from "./utils";
import {Card, Col, Row, Statistic} from "antd";

export default function QuestionStats({questions}: { questions: QuestionRow[] }) {
    return (
        <Row gutter={8} style={{marginBottom: 16}}>
            {
                Object.entries(catDict).map(([cat, label]) => (
                    <Col span={6} key={cat}>
                        <Card>
                            <Statistic
                                title={label}
                                value={questions.filter(row => row.category === cat).length}
                                suffix="teig."
                            />
                        </Card>
                    </Col>
                ))
            }
        </Row>
    )
}
