import {Spin} from "antd";
import "./styles.css";

export default function Loading() {

    return (
        <div className="loading-wrapper">
            <Spin className="spinner" size="large" tip="Kraunama..." />
        </div>
    )
}
